<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Usuarios',
              quantity: data.counters.users.total,
              icon: 'users',
              change: data.counters.users.actives,
              changeVar: 'usuarios activos'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row items-center">
          <div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="[
                { id: 0, name: 'Inactivo' },
                { id: 1, name: 'Activo' }
              ]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_role"
              placeholder="Rol"
              :options="[
                { id: 1, name: 'Administrador' },
                { id: 2, name: 'Reportero' },
                { id: 3, name: 'Anotador' }
              ]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          class="my-auto mr-2 h-fit py-2 lg:text-sm xl:text-md"
          @click="$router.push('/usuarios/crear')"
        >
          Crear usuario
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="user"
          :headers="headers"
          :url="'usuarios'"
          :data="users"
          :route="'/user'"
          :tableName="'USUARIOS'"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: ["", "Nombres", "Apellidos", "Correo", "Rol", "Acciones"],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        order_key: "id",
        order_value: "desc",
        id_role: null,
        active: 1
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    users() {
      return this.$store.getters["global/getItems"]("user", "elements").map(
        (el) => ({
          id: el.id,
          image: el.profile_picture,
          first_name: el.first_name,
          last_name: el.last_name,
          email: el.email,
          role: this.$store.state.user.roles[el.id_role],
          active: el.active
        })
      )
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.users.actives--
    },
    activate() {
      this.data.counters.users.actives++
    },
    erase() {
      this.data.counters.users.total--
      this.data.counters.users.actives--
    }
  },
  created() {
    this.getCounters()
  }
}
</script>
